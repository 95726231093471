import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Drawer } from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import PlaceIcon from '@mui/icons-material/Place';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { useNavigate, useLocation } from 'react-router-dom';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import PublicIcon from '@mui/icons-material/Public';
import TravelExploreIcon from '@mui/icons-material/TravelExplore'; 
import './Sidebar.css'; // Importa el archivo CSS

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <Drawer
            className="drawer" // Aplica la clase CSS para el fondo
            sx={{ width: 240, flexShrink: 0 }}
            variant="permanent"
            anchor="left"
        >
            <List>
                <ListItem
                    button
                    onClick={() => handleNavigation('/recorrido')}
                    className={location.pathname === '/recorrido' ? 'list-item selected' : 'list-item'}
                >
                    <ListItemIcon>
                        <DirectionsBusIcon />
                    </ListItemIcon>
                    <ListItemText primary="Recorrido de Buses" />
                </ListItem>
                <ListItem
                    button
                    onClick={() => handleNavigation('/marcadores-interes')}
                    className={location.pathname === '/marcadores-interes' ? 'list-item selected' : 'list-item'}
                >
                    <ListItemIcon>
                        <PlaceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Capa Marcador" />
                </ListItem>
                <ListItem
                    button
                    onClick={() => handleNavigation('/consulta-marcadores')}
                    className={location.pathname === '/consulta-marcadores' ? 'list-item selected' : 'list-item'}
                >
                    <ListItemIcon>
                        <EditLocationAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Consulta Capa Marcador" />
                </ListItem>
    
                <ListItem
                    button
                    onClick={() => handleNavigation('/marca-poligonos')}
                    className={location.pathname === '/marca-poligonos' ? 'list-item selected' : 'list-item'}
                >
                    <ListItemIcon>
                        <ZoomInMapIcon />
                    </ListItemIcon>
                    <ListItemText primary=" Capa Forma" />
                </ListItem>
                
                {/* Nuevo ListItem para Consulta Polígonos */}
                <ListItem
                    button
                    onClick={() => handleNavigation('/consulta-poligonos')}
                    className={location.pathname === '/consulta-poligonos' ? 'list-item selected' : 'list-item'}
                >
                    <ListItemIcon>
                        <ViewInArIcon />
                    </ListItemIcon>
                    <ListItemText primary="Editar Capa Forma" />
                </ListItem>
                
                <ListItem
                button
                onClick={() => handleNavigation('/my-maps')}
                className={location.pathname === '/my-maps' ? 'list-item selected' : 'list-item'}
            >
                <ListItemIcon>
                    <TravelExploreIcon />
                </ListItemIcon>
                <ListItemText primary="Crear My Maps" />
            </ListItem>
            
            {/* Icono actualizado para Consultar My Maps */}
            <ListItem
                button
                onClick={() => handleNavigation('/my-maps-search')}
                className={location.pathname === '/my-maps-search' ? 'list-item selected' : 'list-item'}
            >
                <ListItemIcon>
                    <PublicIcon />
                </ListItemIcon>
                <ListItemText primary="Consultar My Maps" />
            </ListItem>
        </List>
    </Drawer>
);
    
};

export default Sidebar;
