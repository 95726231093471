import React, { useState, useEffect } from 'react';
import { Button, TextField, Box, Snackbar, Alert } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as XLSX from 'xlsx';
import axios from 'axios';
import PoligonColorPicker from '../../components/poligonos/poligonColorPicker';
import './marcaPoligonos.css';

const MarcaPoligonos = () => {
  const [data, setData] = useState([]);
  const [zona, setZona] = useState('');
  const [color, setColor] = useState('#FF0000'); // Estado para almacenar el color seleccionado
  const [excelCargado, setExcelCargado] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [map, setMap] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const [drawingMode, setDrawingMode] = useState(false); // Estado para controlar el modo de dibujo
  const [drawingPolygon, setDrawingPolygon] = useState(null); // Polígono en proceso de dibujo
  const [polygons, setPolygons] = useState([]); // Almacena todos los polígonos creados


  useEffect(() => {
    if (window.google) {
      initMap();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD1Nl9yulU0jgl6VRGkK89neIalMrblrws&callback=initMap`;
      script.async = true;
      document.body.appendChild(script);
      window.initMap = initMap;
    }
  }, []);

  const initMap = () => {
    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.391911, lng: -58.661421 },
      zoom: 12,
    });
    setMap(mapInstance);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          if (!sheet) {
            throw new Error('No se pudo leer la hoja del archivo');
          }
          const json = XLSX.utils.sheet_to_json(sheet);
          if (json.length === 0) {
            throw new Error('La hoja está vacía');
          }
          setData(json);
          setExcelCargado(true);
          drawPolygon(json, color);
        } catch (error) {
          setData([]);
          setExcelCargado(false);
          setAlertMessage('Error al cargar el archivo');
          setAlertSeverity('error');
          setAlertOpen(true);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const drawPolygon = (data, selectedColor) => {
    if (map && data.length > 0) {
      const coordinates = data.map(row => ({
        lat: parseFloat(row.lat),
        lng: parseFloat(row.lng),
      }));

      if (polygon) {
        polygon.setMap(null);
      }

      const newPolygon = new window.google.maps.Polygon({
        paths: coordinates,
        strokeColor: selectedColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: selectedColor,
        fillOpacity: 0.35,
        draggable: false,
        editable: true,
      });

      newPolygon.setMap(map);
      setPolygon(newPolygon);
    }
  };

  const handleSave = () => {
    if (!zona || data.length === 0) {
      setAlertMessage('Zona o datos faltantes');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    axios.post('https://mapane3.tipicaweb.com/api/api/savePolygon', {
      nombre_zona: zona,
      puntos: data,
      color: color,
    })
      .then(() => {
        setAlertMessage('Datos guardados exitosamente');
        setAlertSeverity('success');
        setAlertOpen(true);
        setData([]);
        setExcelCargado(false);
        setZona('');
        document.getElementById('file-upload').value = '';
        if (polygon) {
          polygon.setMap(null);
          setPolygon(null);
        }
      })
      .catch(() => {
        setAlertMessage('Error al guardar los datos');
        setAlertSeverity('error');
        setAlertOpen(true);
      });
  };

  const toggleDrawingMode = () => {
    if (drawingMode) {
      // Finalizar el modo de dibujo
      if (drawingPolygon) {
        const paths = drawingPolygon.getPaths().getArray()[0].getArray();
        const puntos = paths.map(path => ({ lat: path.lat(), lng: path.lng() }));
        setData(puntos);
  
        // Deshabilitar la edición del polígono
        drawingPolygon.setOptions({ editable: false });
  
        // Guardar el polígono en el estado de polígonos
        setPolygons((prevPolygons) => [...prevPolygons, drawingPolygon]);
  
        // Limpiar el polígono en proceso de dibujo
        setDrawingPolygon(null);
      }
      setDrawingMode(false);
    } else {
      // Iniciar el modo de dibujo
      setDrawingMode(true);
  
      // Limpiar el polígono en proceso de dibujo (si existe)
      if (drawingPolygon) {
        drawingPolygon.setMap(null);
        setDrawingPolygon(null);
      }
    }
  };

  useEffect(() => {
    if (map && drawingMode) {
      const newPolygon = new window.google.maps.Polygon({
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.35,
        editable: true, // Permitir edición solo durante el dibujo
      });
  
      newPolygon.setMap(map);
      setDrawingPolygon(newPolygon);
  
      // Escuchar eventos de clic para agregar vértices al polígono
      map.addListener('click', (event) => {
        const path = newPolygon.getPaths().getArray()[0];
        path.push(event.latLng);
      });
    }
  }, [drawingMode, map, color]);

  return (
    <div className="container2">
      <Box className="file-controls2">
        <input
          accept=".xlsx, .xls"
          style={{ display: 'none' }}
          id="file-upload"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="file-upload">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
            id="excel-upload-button2"
          >
            Cargar Excel
          </Button>
        </label>
        {excelCargado && (
          <Box display="flex" alignItems="center">
            <CheckCircleIcon color="success" />
            <span>Archivo Excel cargado</span>
          </Box>
        )}
        <Box className="input-container">
          <TextField
            id="zona-input"
            placeholder="Zona"
            value={zona}
            onChange={(e) => setZona(e.target.value)}
            className="custom-input2"
          />
        </Box>
        <PoligonColorPicker
          selectedColor={color}
          onChange={(newColor) => {
            setColor(newColor);
            if (polygon) {
              polygon.setOptions({
                strokeColor: newColor,
                fillColor: newColor,
              });
            }
            if (drawingPolygon) {
              drawingPolygon.setOptions({
                strokeColor: newColor,
                fillColor: newColor,
              });
            }
          }}
        />
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSave}
          id="save-button2"
        >
          Guardar
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: drawingMode ? '#FF0000' : '#03A63C', color: 'white' }}
          onClick={toggleDrawingMode}
          startIcon={<img src="https://img.icons8.com/?size=100&id=Si0V2yDb01x6&format=png&color=000000" alt="Crear Forma" width="20" height="32" />}
        >
          {drawingMode ? 'Finalizar Forma' : 'Crear Forma'}
        </Button>
      </Box>
      <Box mt={4}>
        <div id="map" className="map2"></div>
      </Box>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MarcaPoligonos;
