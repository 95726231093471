import React, { useState, useEffect, useRef  } from 'react';
import {
  Autocomplete,Button,TextField, Box, List, ListItem, ListItemText,IconButton,Snackbar, Alert,
   Grid, Dialog,DialogActions,DialogContent,DialogTitle,Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import './mymaps.css';
import BusCheckboxes from './BusCheckboxes';
import IconSelector from '../commons/IconSelector';
import ColorPicker from '../buses/ColorPicker'; // Importa el ColorPicker

const Mymaps = () => {
  const [buses, setBuses] = useState([]);
  const [puntosInteres, setPuntosInteres] = useState([]);
  const [poligonos, setPoligonos] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]);
  const [filters, setFilters] = useState({
    punto: '',
    linea: '',
    poligono: '',
  });

  const [map, setMap] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [saveMapOpen, setSaveMapOpen] = useState(false); // Para el estado del diálogo de guardar mapa
  const [mapName, setMapName] = useState(''); // Para el nombre del mapa
  const [iconDialogOpen, setIconDialogOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null); // Para almacenar la coordenada del punto seleccionado
  const [pointName, setPointName] = useState(''); // Para almacenar el nombre del punto
  const [pointDialogOpen, setPointDialogOpen] = useState(false); // Para controlar la apertura del pop-up
  const [isDrawing, setIsDrawing] = useState(false); // Estado para controlar si estamos dibujando
  const [polygonPoints, setPolygonPoints] = useState([]); // Almacena los puntos del polígono
  const [polygonDialogOpen, setPolygonDialogOpen] = useState(false); // Controla el diálogo de guardar polígono
  const [nombreZona, setNombreZona] = useState(''); // Nombre de la zona
  const [selectedColor, setSelectedColor] = useState('#FF0000'); // Color seleccionado
  const polygonRef = useRef(null); // Referencia al polígono en el mapa

  useEffect(() => {
    if (window.google) {
      initMap();
    } else {
      const script = document.createElement('script');
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyD1Nl9yulU0jgl6VRGkK89neIalMrblrws&callback=initMap";
      script.async = true;
      document.body.appendChild(script);
      window.initMap = initMap;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (filters.punto) {
        const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getPuntosInteres/${filters.punto}`);
        const data = response.data;
        const groupedData = data.reduce((acc, punto) => {
          if (!acc[punto.nombre]) {
            acc[punto.nombre] = [];
          }
          acc[punto.nombre].push(punto);
          return acc;
        }, {});
        setPuntosInteres(groupedData);
      } else {
        setPuntosInteres([]);
      }

      if (filters.poligono) {
        const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getPolygonPoints/${filters.poligono}`);
        const data = response.data;
        const groupedData = data.reduce((acc, poligono) => {
          if (!acc[poligono.nombre_zona]) {
            acc[poligono.nombre_zona] = [];
          }
          acc[poligono.nombre_zona].push(poligono);
          return acc;
        }, {});
        setPoligonos(groupedData);
      } else {
        setPoligonos([]);
      }
    };

    fetchData();
  }, [filters]);

  const initMap = () => {
    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.391911, lng: -58.661421 },
      zoom: 12,
    });
    setMap(mapInstance);
  };

  //dialog seleccion de punto de interes 
 

  const closeIconDialog = () => {
    setIconDialogOpen(false);
  };

  const handleSelectIcon = (icon) => {
    setSelectedIcon(icon); // Guardar el ícono seleccionado
    setIconDialogOpen(false); // Cerrar el diálogo de selección de ícono
    setPointDialogOpen(true); // Abrir el diálogo para asignar nombre al punto
  };

  //metodo click en el mapa para agregar el punto 
  useEffect(() => {
    if (map) {
      // Evento de clic en el mapa
      window.google.maps.event.addListener(map, 'dblclick', (event) => {
        const latLng = event.latLng;
        setSelectedPoint(latLng); // Guardar la coordenada del clic
        setIconDialogOpen(true); // Abrir el diálogo de selección de ícono
      });
    }
  }, [map]); // Dependencia: map
  
  //metodo para guardar el punto haciendo click en el mapa 
  const handleAddPoint = async () => {
    if (selectedPoint && pointName && selectedIcon) {
      try {
        // Obtener la dirección y localidad usando Geocoding
        const geocodeResponse = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              latlng: `${selectedPoint.lat()},${selectedPoint.lng()}`,
              key: 'AIzaSyDhV1-TmsTWBVC3TMbc_ATcflMHSC2Y9Wo', // Tu clave de API de Google Maps
            },
          }
        );
  
        if (geocodeResponse.data.status !== 'OK') {
          throw new Error('No se pudo obtener la dirección desde las coordenadas');
        }
  
        // Extraer la dirección y localidad de la respuesta
        const addressComponents = geocodeResponse.data.results[0].address_components;
        const direccion = geocodeResponse.data.results[0].formatted_address; // Dirección completa
        const localidad = addressComponents.find((component) =>
          component.types.includes('locality')
        )?.long_name; // Nombre de la localidad
  
        if (!direccion || !localidad) {
          throw new Error('No se pudo obtener la dirección o localidad');
        }
  
        // Crear el objeto del punto de interés
        const newPoint = {
          lat: selectedPoint.lat(),
          lng: selectedPoint.lng(),
          icono: selectedIcon,
          nombre: pointName,
          direccion,
          localidad,
        };
  
        // Crear el marcador en el mapa
        const marker = new window.google.maps.Marker({
          position: { lat: newPoint.lat, lng: newPoint.lng },
          map,
          title: newPoint.nombre,
          icon: newPoint.icono,
        });
        
        // Agregar evento de clic derecho para eliminar el marcador
        marker.addListener('click', () => {
          marker.setMap(null);
          setSelectedElements((prevElements) =>
            prevElements.filter(el => el.marker !== marker)
          );
          
          setAlertSeverity('info');
          setAlertOpen(true);
        });
        
  
        // Enviar el punto al backend
        const response = await axios.post('https://mapane3.tipicaweb.com/api/api/uploadPuntosInteres', {
          data: [
            {
              lat: newPoint.lat,
              lng: newPoint.lng,
              direccion: newPoint.direccion,
              localidad: newPoint.localidad,
            },
          ],
          nombre: newPoint.nombre,
          icono: newPoint.icono,
        });
  
        // Mostrar mensaje de éxito
        setAlertMessage('Punto de interés guardado exitosamente');
        setAlertSeverity('success');
        setAlertOpen(true);
  
        // Agregar el nuevo punto a selectedElements
        setSelectedElements((prevElements) => [
          ...prevElements,
          {
            nombre: newPoint.nombre,
            type: 'punto',
            data: [newPoint],
            marker: marker,
          },
        ]);
  
        // Cerrar el diálogo y limpiar el estado
        setPointDialogOpen(false);
        setPointName('');
        setSelectedIcon(null);
      } catch (error) {
        console.error('Error al guardar el punto de interés:', error);
        setAlertMessage('Error al guardar el punto de interés: ' + error.message);
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } else {
      setAlertMessage('Por favor, seleccione un ícono y asigne un nombre al punto');
      setAlertSeverity('warning');
      setAlertOpen(true);
    }
  };

  // Función para alternar entre "Crear poligono" y "Finalizar poligono"
  const toggleDrawing = () => {
    if (isDrawing) {
      // Si estamos finalizando, abrimos el diálogo para guardar el polígono
      setPolygonDialogOpen(true);
    } else {
      // Si estamos comenzando, limpiamos los puntos anteriores
      setPolygonPoints([]);
      if (polygonRef.current) {
        polygonRef.current.setMap(null); // Elimina el polígono anterior del mapa
      }
    }
    setIsDrawing(!isDrawing); // Cambiamos el estado
  };

  // Función para guardar el polígono en el backend
  const handleSavePolygon = async () => {
    if (!nombreZona || polygonPoints.length === 0) {
      setAlertMessage('Por favor, ingresa un nombre y asegúrate de haber creado un polígono.');
      setAlertSeverity('warning');
      setAlertOpen(true);
      return;
    }
  
    try {
      const polygonData = polygonPoints.map((punto) => ({
        nombre_zona: nombreZona,
        lat: punto.lat,
        lng: punto.lng,
        color: selectedColor,
      }));
  
      const response = await axios.post('https://mapane3.tipicaweb.com/api/api/savePolygon', {
        nombre_zona: nombreZona,
        puntos: polygonData,
        color: selectedColor,
      });
  
      if (response.status === 200) {
        // Crear una nueva instancia del polígono no editable
        const finalizedPolygon = new window.google.maps.Polygon({
          paths: polygonPoints.map((p) => ({ lat: p.lat, lng: p.lng })),
          strokeColor: selectedColor,
          fillColor: selectedColor,
          editable: false, // Asegurarse que no sea editable
          map,
        });
  
        // Eliminar el polígono editable anterior
        if (polygonRef.current) {
          polygonRef.current.setMap(null);
        }
  
        // Actualizar la referencia con el nuevo polígono no editable
        polygonRef.current = finalizedPolygon;
  
        // Agregar el polígono a la lista de selectedElements
        const newPolygon = {
          nombre: nombreZona,
          type: 'polygon',
          data: polygonData,
          polygon: finalizedPolygon,
        };
  
        setSelectedElements((prevElements) => [...prevElements, newPolygon]);
  
        // Mostrar mensaje de éxito
        setAlertMessage('Forma guardado exitosamente');
        setAlertSeverity('success');
        setAlertOpen(true);
  
        // Limpiar el estado
        setPolygonDialogOpen(false);
        setNombreZona('');
        setSelectedColor('#FF0000');
        setIsDrawing(false);
        setPolygonPoints([]); // Limpiar los puntos
      }
    } catch (error) {
      console.error('Error al guardar la Forma:', error);
      setAlertMessage('Error al guardar la Forma');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };
  // Efecto para manejar la creación del polígono en el mapa
  useEffect(() => {
    if (map && isDrawing) {
      // Evento de clic en el mapa para agregar puntos al polígono
      const clickListener = window.google.maps.event.addListener(map, 'click', (event) => {
        setPolygonPoints((prevPoints) => [...prevPoints, { lat: event.latLng.lat(), lng: event.latLng.lng() }]);
      });

      // Limpiar el listener cuando se desactiva el dibujo
      return () => {
        window.google.maps.event.removeListener(clickListener);
      };
    }
  }, [map, isDrawing]);

  
  // Efecto para dibujar el polígono en el mapa
  useEffect(() => {
    if (!map || polygonPoints.length === 0) return;
  
    // Elimina el polígono anterior si existe
    if (polygonRef.current) {
      window.google.maps.event.clearInstanceListeners(polygonRef.current); // Limpia listeners antiguos
      polygonRef.current.setMap(null);
    }
  
    // Crea un nuevo polígono
    const newPolygon = new window.google.maps.Polygon({
      paths: polygonPoints.map((p) => ({ lat: p.lat, lng: p.lng })),
      strokeColor: selectedColor,
      fillColor: selectedColor,
      editable: true,
      map,
    });
  
    // Función para actualizar el estado con los puntos actuales
    const updatePolygonState = () => {
      const currentPaths = newPolygon
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
      setPolygonPoints(currentPaths);
    };
  
    // Listeners para edición
    newPolygon.getPath().addListener('insert_at', updatePolygonState);
    newPolygon.getPath().addListener('set_at', updatePolygonState);
    newPolygon.getPath().addListener('remove_at', updatePolygonState);
  
    // Guarda referencia al polígono
    polygonRef.current = newPolygon;
  
    // Limpieza al desmontar
    return () => {
      if (newPolygon) {
        window.google.maps.event.clearInstanceListeners(newPolygon);
      }
    };
  }, [polygonPoints, selectedColor, map]);

  // Efecto para actualizar el color del polígono
  useEffect(() => {
    if (polygonRef.current) {
      // Actualiza el color del polígono
      polygonRef.current.setOptions({
        strokeColor: selectedColor,
        fillColor: selectedColor,
      });
    }
  }, [selectedColor]);

  //Funcion para cancelar y limpiar el poligono 

  const handleCancelPolygon = () => {
    setPolygonDialogOpen(false); // Cierra el diálogo
    setIsDrawing(false); // Desactiva el modo de dibujo
    setPolygonPoints([]); // Limpia los puntos del polígono
    setNombreZona(''); // Limpia el nombre de la zona
    setSelectedColor('#FF0000'); // Restablece el color
  
    // Elimina el polígono del mapa
    if (polygonRef.current) {
      polygonRef.current.setMap(null);
      polygonRef.current = null;
    }
  };
  

  const handleSelectElement = (nombre, data, type) => { 
    setSelectedElements(prevElements => {
      const existingElement = prevElements.find(el => el.nombre === nombre && el.type === type);
      
      if (existingElement) {
        // Si el elemento ya existe, lo eliminamos (deseleccionamos)
        handleRemoveElement(nombre);  // Llama a la función para eliminar el polyline
        return prevElements.filter(el => el.nombre !== nombre); // Remueve de la lista
      } else {
        // Añadir el nuevo elemento sin interactuar con el mapa aún
        const newElement = { nombre, data, type };
        return [...prevElements, newElement];
      }
    });
};

  
  
useEffect(() => {
  if (map) {
    selectedElements.forEach(element => {
      // Manejo de marcadores
      if (!element.marker && element.type === 'punto') {
        const markers = element.data.map(punto => {
          const marker = new window.google.maps.Marker({
            position: { lat: parseFloat(punto.lat), lng: parseFloat(punto.lng) },
            map,
            title: punto.nombre,
            icon: punto.icono || null,
          });
          return marker;
        });
        element.marker = markers; // Guardar los marcadores en el elemento
      }
      // Manejo de polilíneas
      else if (element.type === 'bus') {
        console.log('Procesando buses (element.data):', element.data);

        // Agrupar datos por línea para crear polilíneas separadas
        const lines = {};

        element.data.forEach(bus => {
          const lineName = bus.linea;
          const color = bus.color || '#FF0000'; // Color por defecto

          if (!lines[lineName]) {
            lines[lineName] = {
              path: [],
              color: color,
            };
          }

          // Agregar la coordenada a la línea correspondiente
          lines[lineName].path.push({
            lat: parseFloat(bus.lat),
            lng: parseFloat(bus.lng),
          });
        });

        // Crear y asignar una polilínea para cada línea si no existe ya
        Object.keys(lines).forEach(lineName => {
          const existingPolyline = element.polyline?.find(poly => poly.name === lineName);

          if (!existingPolyline) {
            const newPolyline = new window.google.maps.Polyline({
              path: lines[lineName].path,
              geodesic: true,
              strokeColor: lines[lineName].color,
              strokeOpacity: 1.0,
              strokeWeight: 2,
              map,
            });

            newPolyline.name = lineName; // Asignar nombre para identificarla
            element.polyline = [...(element.polyline || []), newPolyline];
          }
        });
      }
      // Manejo de polígonos
      else if (!element.polygon && element.type === 'polygon') {
        const polygonPath = element.data.map(p => ({
          lat: parseFloat(p.lat),
          lng: parseFloat(p.lng),
        }));
        const polygon = new window.google.maps.Polygon({
          paths: polygonPath,
          strokeColor: element.data[0]?.color || '#FF0000', // Color de polígono
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: element.data[0]?.color || '#FF0000', // Color de relleno
          fillOpacity: 0.35,
          map,
        });
        element.polygon = polygon;
      }
    });
  }
}, [selectedElements, map]);

const handleRemoveElement = (nombre) => {
  setSelectedElements(prev => {
    // Buscar el elemento a eliminar
    const elementToRemove = prev.find(el => el.nombre === nombre);

    if (elementToRemove) {
      // Eliminar el marcador del mapa si existe
      if (elementToRemove.marker) {
        elementToRemove.marker.setMap(null); // Eliminar el marcador del mapa
        console.log(`Marcador de "${nombre}" eliminado del mapa.`);
      }

      // Eliminar el polígono del mapa si existe
      if (elementToRemove.polygon) {
        elementToRemove.polygon.setMap(null);
        console.log(`Polígono de "${nombre}" eliminado del mapa.`);
      }

      // Eliminar la polilínea del mapa si existe
      if (elementToRemove.polyline) {
        if (Array.isArray(elementToRemove.polyline)) {
          elementToRemove.polyline.forEach(poly => poly.setMap(null));
        } else {
          elementToRemove.polyline.setMap(null);
        }
        console.log(`Polilínea de "${nombre}" eliminada del mapa.`);
      }
    }

    // Filtrar el elemento eliminado de la lista
    const updatedElements = prev.filter(el => el.nombre !== nombre);

    // Mostrar mensaje de éxito
    setAlertMessage(`Elemento "${nombre}" eliminado del mapa.`);
    setAlertSeverity('info');
    setAlertOpen(true);

    return updatedElements;
  });
};

  


  const openSaveMapPopup = () => {
    setSaveMapOpen(true);
  };

  const closeSaveMapPopup = () => {
    setSaveMapOpen(false);
  };

  const handleSaveMap = () => {
    const mapData = selectedElements.map(el => ({
      nombre: el.nombre,
      type: el.type,
      data: el.data,
    }));
  
    axios.post('https://mapane3.tipicaweb.com/api/api/saveMap', { name: mapName, data: mapData })
      .then(response => {
        setAlertMessage('Mapa guardado exitosamente');
        setAlertSeverity('success');
        setAlertOpen(true);
        closeSaveMapPopup();
        setMapName(''); // Reiniciar el nombre del mapa
  
        // Limpiar los elementos seleccionados y los filtros
        setSelectedElements([]);
        setFilters({
          punto: '',
          linea: '',
          poligono: '',
        });
      })
      .catch(error => {
        setAlertMessage('Error al guardar el mapa');
        setAlertSeverity('error');
        setAlertOpen(true);
      });
  };
  
  
  

  

  return (
    <Box className="container">
      <Box className="filters-container" display="flex" justifyContent="space-between" mb={2}>
        
        {/* Autocomplete para Puntos de Interés */}
        <Box className="filter">
          <Autocomplete
            className="custom-autocomplete"
            options={Object.keys(puntosInteres).filter(nombre => 
              !selectedElements.some(el => el.nombre === nombre && el.type === 'punto') // Filtrar elementos ya seleccionados
            )}
            getOptionLabel={(option) => option}
            onInputChange={(event, newValue) => setFilters({ ...filters, punto: newValue })}
            onChange={(event, newValue) => {
              if (newValue) {
                handleSelectElement(newValue, puntosInteres[newValue], 'punto');
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Capa Marcador"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Box>
  
        {/* Checkbox para Recorridos de Buses */}
      <Box className="filter">
        <BusCheckboxes selectedElements={selectedElements} handleSelectElement={handleSelectElement}
        handleRemoveElement={handleRemoveElement}
        />
      </Box>
  
        {/* Autocomplete para Polígonos */}
        <Box className="filter">
          <Autocomplete
            className="custom-autocomplete"
            options={Object.keys(poligonos).filter(nombre => 
              !selectedElements.some(el => el.nombre === nombre && el.type === 'polygon') // Filtrar elementos ya seleccionados
            )}
            getOptionLabel={(option) => option}
            onInputChange={(event, newValue) => setFilters({ ...filters, poligono: newValue })}
            onChange={(event, newValue) => {
              if (newValue) {
                handleSelectElement(newValue, poligonos[newValue], 'polygon');
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Capa Formas"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Box>
      </Box>
  
      <Grid container spacing={2} mt={2}>
      <Grid item xs={4}>
  <Box>
    <h3>Capa Marcador</h3>
    <List>
      {selectedElements
        .filter(el => el.type === 'punto')
        .map(element => (
          <ListItem key={element.nombre}>
            <ListItemText primary={element.nombre} />
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleRemoveElement(element.nombre)} // Eliminar el punto
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
    </List>
  </Box>
</Grid>
        
        
        <Grid item xs={4}>
          <Box>
            <h3>Capa Formas</h3>
            <List>
              {selectedElements
                .filter(el => el.type === 'polygon')
                .map(element => (
                  <ListItem key={element.nombre}>
                    <ListItemText primary={element.nombre} />
                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveElement(element.nombre)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>

      
      <Button
        id="save-map-button"
        variant="contained"
        startIcon={<SaveIcon />}
        onClick={openSaveMapPopup}
      >
        Guardar Mapa
      </Button>

      <Dialog open={saveMapOpen} onClose={closeSaveMapPopup}>
        <DialogTitle>Guardar Mapa</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre del Mapa"
            fullWidth
            value={mapName}
            onChange={(e) => setMapName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSaveMapPopup}>Cancelar</Button>
          <Button onClick={handleSaveMap}>Guardar</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>

      {/* seleccion del punto de interes*/ }
      

      <Dialog open={iconDialogOpen} onClose={closeIconDialog}>
        <DialogTitle>Seleccionar Ícono</DialogTitle>
        <DialogContent>
          <IconSelector onSelectIcon={handleSelectIcon} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeIconDialog}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={pointDialogOpen} onClose={() => setPointDialogOpen(false)}>
  <DialogTitle>Agregar Capa Marcador</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Nombre del Punto"
      fullWidth
      value={pointName}
      onChange={(e) => setPointName(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setPointDialogOpen(false)}>Cancelar</Button>
    <Button onClick={handleAddPoint}>Aceptar</Button>
  </DialogActions>
</Dialog>

  {/* Botón para crear/finalizar forma */}
  <Button
        id="create-shape-button"
        variant="contained"
        startIcon={<img src="https://img.icons8.com/?size=100&id=Si0V2yDb01x6&format=png&color=000000" alt="Crear Forma" width="24" height="24" />}
        style={{
          backgroundColor: isDrawing ? '#FF0000' : '#4CAF50', // Rojo si está dibujando, verde si no
          color: '#FFF',
          marginBottom: '10px',
        }}
        onClick={toggleDrawing}
      >
        {isDrawing ? 'Finalizar forma' : 'Crear forma'}
      </Button>

       {/* Diálogo para guardar el polígono */}
       <Dialog open={polygonDialogOpen} onClose={handleCancelPolygon}>
        <DialogTitle>Guardar Capa Forma</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre de la Forma"
            fullWidth
            value={nombreZona}
            onChange={(e) => setNombreZona(e.target.value)}
          />
          {/* Pasa la prop "onChange" en lugar de "onColorChange" */}
          <ColorPicker
            selectedColor={selectedColor}
            onChange={(color) => setSelectedColor(color)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelPolygon}>Cancelar</Button>
          <Button onClick={handleSavePolygon}>Guardar</Button>
        </DialogActions>
      </Dialog>


      {/* Mapa */}
      <Box id="map" className="map-container" />
    </Box>
  );
  
};
export default Mymaps;