import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import styles from './marcadoresMapa.module.css';

const MarcadoresMapa = () => {
  const { nombre } = useParams(); // Obtenemos el nombre desde los parámetros de la URL
  const [marcadores, setMarcadores] = useState([]);
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    const fetchMarcadores = async () => {
      try {
        const response = await fetch(
          `https://mapane3.tipicaweb.com/api/api/getPuntosInteresPorNombre/${nombre}`
        );
        if (!response.ok) {
          throw new Error('Error al obtener los marcadores');
        }
        const data = await response.json();

        // Validamos y transformamos los datos
        const marcadoresValidos = data.map((marker) => ({
          ...marker,
          lat: parseFloat(marker.lat),
          lng: parseFloat(marker.lng),
        })).filter((marker) => !isNaN(marker.lat) && !isNaN(marker.lng));

        setMarcadores(marcadoresValidos);
      } catch (error) {
        console.error('Error al cargar marcadores:', error);
      } finally {
        setCargando(false);
      }
    };

    fetchMarcadores();
  }, [nombre]);

  const calculateCenter = () => {
    if (marcadores.length === 0) {
      return { lat: -34.6037, lng: -58.3816 }; // Coordenadas predeterminadas
    }
    const latitudes = marcadores.map((marker) => marker.lat);
    const longitudes = marcadores.map((marker) => marker.lng);
    const avgLat = latitudes.reduce((a, b) => a + b, 0) / latitudes.length;
    const avgLng = longitudes.reduce((a, b) => a + b, 0) / longitudes.length;
    return { lat: avgLat, lng: avgLng };
  };

  const mapContainerStyle = {
    width: '100%',
    height: '500px', // Ajusta la altura según sea necesario
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyDhV1-TmsTWBVC3TMbc_ATcflMHSC2Y9Wo">
      <div className={styles.container}>
        {cargando ? (
          <div>Cargando...</div>
        ) : (
          <>
            <h2>Mapa de Marcadores: {nombre}</h2>
            {marcadores.length === 0 ? (
              <div>No se encontraron marcadores para "{nombre}".</div>
            ) : (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={calculateCenter()}
                zoom={12}
              >
                {marcadores.map((marker) => (
                  <Marker
                    key={marker.id}
                    position={{
                      lat: marker.lat,
                      lng: marker.lng,
                    }}
                    icon={{
                      url: marker.icono || '/path/to/default/icon.png', // Ícono predeterminado
                      scaledSize: { width: 40, height: 40 },
                    }}
                    title={marker.direccion || 'Sin dirección'}
                  />
                ))}
              </GoogleMap>
            )}
          </>
        )}
      </div>
    </LoadScript>
  );
};

export default MarcadoresMapa;
