import React, { useEffect } from 'react';

const MapWithMarkers = ({ data, iconoSeleccionado, onMarkerClick }) => {
    useEffect(() => {
        const initMap = () => {
            const center = new window.google.maps.LatLng(-34.603722, -58.381592);
            const map = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 9,
                center: center,
            });

            // Crear un array para almacenar los marcadores
            const markers = [];

            // Coloca los marcadores en el mapa con el ícono correspondiente
            data.forEach((point) => {
                const icono = point.icono || iconoSeleccionado || "https://maps.google.com/mapfiles/kml/pal2/icon19.png"; // Usa el icono específico del punto o el seleccionado, o el predeterminado

                const marker = new window.google.maps.Marker({
                    position: { lat: parseFloat(point.lat), lng: parseFloat(point.lng) },
                    map: map,
                    title: point.nombre || 'Sin nombre', // Nombre del punto
                    icon: icono, // Usar el ícono específico del marcador o el predeterminado
                });

                // Agregar evento de clic para eliminar el marcador
                marker.addListener('click', () => {
                    onMarkerClick(point.id); // Llamar a la función del padre para eliminar el marcador
                    marker.setMap(null); // Eliminar el marcador del mapa
                });

                // Guardar el marcador en el array
                markers.push(marker);
            });

            // Limpiar los marcadores cuando el componente se actualice o se destruya
            return () => {
                markers.forEach((marker) => {
                    marker.setMap(null); // Eliminar los marcadores del mapa
                });
            };
        };

        if (window.google) {
            initMap();
        } else {
            window.initMap = initMap;
        }
    }, [data, iconoSeleccionado, onMarkerClick]); // Ejecuta el efecto cuando cambien data o iconoSeleccionado

    return <div id="map" className="mapaux"></div>;
};

export default MapWithMarkers;
