import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress, Snackbar, Alert, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './EditarPoligono.module.css';

const EditarPoligono = () => {
  const { nombre_zona } = useParams();
  const navigate = useNavigate();
  const [poligonoData, setPoligonoData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [map, setMap] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const [color, setColor] = useState('#FF0000');
  const [isSaving, setIsSaving] = useState(false);

  const mapRef = useRef(null);
  const polygonDataRef = useRef(null);

  useEffect(() => {
    fetchPoligono();
    loadGoogleMapsScript(() => setGoogleMapsLoaded(true));
  }, [nombre_zona]);

  useEffect(() => {
    if (googleMapsLoaded && poligonoData) {
      initializeGoogleMap();
    }
  }, [googleMapsLoaded, poligonoData]);

  const fetchPoligono = async () => {
    try {
      const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getPoligono/${nombre_zona}`);
      const data = response.data;

      if (validatePolygon(data)) {
        setPoligonoData(data);
        polygonDataRef.current = data;

        const polygonColor = data[0]?.color || '#FF0000';
        setColor(polygonColor);
      } else {
        throw new Error('Datos de la forma no válidos.');
      }
    } catch (error) {
      setAlertMessage('Error al cargar la forma');
      setAlertSeverity('error');
      setAlertOpen(true);
      console.error('Error al obtener los datos de la Forma:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadGoogleMapsScript = (callback) => {
    // Verifica si la API de Google Maps ya está cargada y lista
    if (window.google && window.google.maps) {
      callback();
      return;
    }
  
    // Verifica si el script de Google Maps ya está en el DOM
    const existingScript = document.querySelector('script[src*="maps.googleapis.com"]');
    if (existingScript) {
      // Si el script ya está en el DOM, espera a que esté listo
      const checkGoogleMaps = () => {
        if (window.google && window.google.maps) {
          callback();
        } else {
          setTimeout(checkGoogleMaps, 100); // Revisar cada 100ms
        }
      };
      checkGoogleMaps();
      return;
    }
  
    // Si el script no está en el DOM, créalo y agrégalo
    const script = document.createElement('script');
    script.id = 'google-maps-script';
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD1Nl9yulU0jgl6VRGkK89neIalMrblrws`;
    script.async = true;
    script.defer = true;
    script.onload = callback;
    script.onerror = handleScriptError;
    document.body.appendChild(script);
  };
  
  const handleScriptError = () => {
    setAlertMessage('Error al cargar Google Maps');
    setAlertSeverity('error');
    setAlertOpen(true);
    console.error('Error al cargar el script de Google Maps.');
  };

  

  const initializeGoogleMap = () => {
    if (!mapRef.current) {
      console.error('No se encontró el contenedor del mapa.');
      return;
    }

    const mapInstance = new window.google.maps.Map(mapRef.current, {
      center: { lat: poligonoData[0]?.lat || 0, lng: poligonoData[0]?.lng || 0 },
      zoom: 14,
    });

    setMap(mapInstance);
    drawPolygon(mapInstance, poligonoData, color);
  };

  const drawPolygon = (mapInstance, data, polygonColor) => {
    const coordinates = data.map((point) => ({
      lat: point.lat,
      lng: point.lng,
    }));

    if (polygon) {
      polygon.setMap(null);
    }

    const newPolygon = new window.google.maps.Polygon({
      paths: coordinates,
      strokeColor: polygonColor,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: polygonColor,
      fillOpacity: 0.35,
      editable: true,
      draggable: true,
    });

    newPolygon.setMap(mapInstance);

    const path = newPolygon.getPath();

    window.google.maps.event.addListener(path, 'set_at', () => debounceUpdatePolygonData(newPolygon));
    window.google.maps.event.addListener(path, 'insert_at', () => debounceUpdatePolygonData(newPolygon));
    window.google.maps.event.addListener(path, 'remove_at', () => debounceUpdatePolygonData(newPolygon));

    setPolygon(newPolygon);
  };

  // Función para actualizar los datos con debounce
  const debounceUpdatePolygonData = debounce((updatedPolygon) => {
    const updatedCoords = updatedPolygon
      .getPath()
      .getArray()
      .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));

    polygonDataRef.current = updatedCoords;
    setPoligonoData(updatedCoords);
  }, 1500); // 500ms de debounce

  const updatePolygonData = (updatedPolygon) => {
    const updatedCoords = updatedPolygon
      .getPath()
      .getArray()
      .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));

    polygonDataRef.current = updatedCoords;
    setPoligonoData(updatedCoords);
  };

  const validatePolygon = (points) => {
    return Array.isArray(points) && points.every((point) => point.lat && point.lng);
  };

  const handleSave = async () => {
    const currentData = polygonDataRef.current;

    if (!currentData || currentData.length === 0 || !validatePolygon(currentData)) {
      setAlertMessage('La capa forma contiene puntos inválidos');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    setIsSaving(true);
    try {
      const dataToSend = currentData.map((point) => ({
        ...point,
        color,
      }));

      await axios.put(`https://mapane3.tipicaweb.com/api/api/updatePolygon`, {
        nombre_zona,
        puntos: dataToSend,
        color,
      });

      setAlertMessage('Forma actualizada exitosamente');
      setAlertSeverity('success');
      setAlertOpen(true);

      await fetchPoligono();
    } catch (error) {
      setAlertMessage('Error al actualizar la capa forma');
      setAlertSeverity('error');
      setAlertOpen(true);
      console.error('Error al guardar la capa forma:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleAlertClose = () => setAlertOpen(false);

  const handleBack = () => {
    navigate('/consulta-poligonos');
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.topButtons}>
        <button className={styles.backButton} onClick={handleBack}>
          <ArrowBackIcon />
          Volver
        </button>
        <Button
          variant="contained"
          className={styles.saveButton}
          onClick={handleSave}
          disabled={isSaving}
          startIcon={<SaveIcon />}
        >
          {isSaving ? 'Guardando...' : 'Guardar'}
        </Button>
      </div>

      <h1 className={styles.title}>Editar Capa Forma: {nombre_zona}</h1>
      <div id="map" ref={mapRef} className={styles.mapContainer}></div>

      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditarPoligono;


function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
}

