import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from '../../shared/header/Header';
import './EditMapPage.css'; // Importa el CSS específico para este componente
import SaveIcon from '@mui/icons-material/Save';

import {
  Autocomplete,Button, TextField, Box, Snackbar, Alert ,List, ListItem,ListItemText,IconButton,
   Grid, Dialog,DialogActions,DialogContent,DialogTitle
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import BusCheckboxes from './BusCheckboxes';
const EditMapPage = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [zona, setZona] = useState('');
   const [puntosInteres, setPuntosInteres] = useState([]);
    const [poligonos, setPoligonos] = useState([]);
  
  const [saveMapOpen, setSaveMapOpen] = useState(false);
  const [excelCargado, setExcelCargado] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [map, setMap] = useState(null); // Guardar la instancia del mapa
  const [polygon, setPolygon] = useState(null); // Guardar la instancia del polígono
const [selectedElements, setSelectedElements] = useState([]);
  const [filters, setFilters] = useState({
    punto: '',
    linea: '',
    poligono: '',
  });
  const [markers, setMarkers] = useState([]);
  const [mapName, setMapName] = useState(''); // Para el nombre del mapa
  

  useEffect(() => {
    if (window.google) {
      initMap();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD1Nl9yulU0jgl6VRGkK89neIalMrblrws&callback=initMap`;
      script.async = true;
      document.body.appendChild(script);
      window.initMap = initMap; // Asigna la función al objeto window
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    
    if (map) {
      fetchMapDetails();
    }
  }, [map]);
useEffect(() => {
  if (map) {
    selectedElements.forEach(element => {
      // Manejo de marcadores
      if (!element.marker && element.type === 'punto') {
        const markers = element.data.map(punto => {
          const marker = new window.google.maps.Marker({
            position: { lat: parseFloat(punto.lat), lng: parseFloat(punto.lng) },
            map,
            title: punto.nombre,
            icon: punto.icono || null,
          });
          return marker;
        });
        element.marker = markers; // Guardar los marcadores en el elemento
      }
      // Manejo de polilíneas
      else if (element.type === 'bus') {
        console.log('Procesando buses (element.data):', element.data);

        // Agrupar datos por línea para crear polilíneas separadas
        const lines = {};

        element.data.forEach(bus => {
          const lineName = bus.linea;
          const color = bus.color || '#FF0000'; // Color por defecto

          if (!lines[lineName]) {
            lines[lineName] = {
              path: [],
              color: color,
            };
          }

          // Agregar la coordenada a la línea correspondiente
          lines[lineName].path.push({
            lat: parseFloat(bus.lat),
            lng: parseFloat(bus.lng),
          });
        });

        // Crear y asignar una polilínea para cada línea si no existe ya
        Object.keys(lines).forEach(lineName => {
          const existingPolyline = element.polyline?.find(poly => poly.name === lineName);

          if (!existingPolyline) {
            const newPolyline = new window.google.maps.Polyline({
              path: lines[lineName].path,
              geodesic: true,
              strokeColor: lines[lineName].color,
              strokeOpacity: 1.0,
              strokeWeight: 2,
              map,
            });

            newPolyline.name = lineName; // Asignar nombre para identificarla
            element.polyline = [...(element.polyline || []), newPolyline];
          }
        });
      }
      // Manejo de polígonos
      else if (!element.polygon && element.type === 'polygon') {
        const polygonPath = element.data.map(p => ({
          lat: parseFloat(p.lat),
          lng: parseFloat(p.lng),
        }));
        const polygon = new window.google.maps.Polygon({
          paths: polygonPath,
          strokeColor: element.data[0]?.color || '#FF0000', // Color de polígono
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: element.data[0]?.color || '#FF0000', // Color de relleno
          fillOpacity: 0.35,
          map,
        });
        element.polygon = polygon;
      }
    });
  }
}, [selectedElements, map]);
  const handleRemoveElement = (nombre) => {
    debugger
    setSelectedElements(prev => {
      // Primero, eliminamos los elementos del mapa
      prev.forEach(element => {
        if (element.nombre === nombre) {
          if (element.polyline) {
            // Eliminar cada instancia de la polilínea del mapa
            if (Array.isArray(element.polyline)) {
              element.polyline.forEach(poly => poly.setMap(null));
            } else {
              element.polyline.setMap(null);
            }
            console.log(`Polyline de "${nombre}" eliminada del mapa.`);
          }
          debugger
          if (element.polygon) {
            element.polygon.setMap(null);
            console.log(`Polígono de "${nombre}" eliminado del mapa.`);
          }
          
          if (element.marker) {
            if (Array.isArray(element.marker)) {
              element.marker.forEach(marker => {
                if (marker) {
                  marker.setMap(null);
                }
              });
            } else if (element.marker) {
              element.marker.setMap(null);
            }
            console.log(`Marcadores de "${nombre}" eliminados del mapa.`);
          }
        }
    });

    return prev.filter(element => element.nombre !== nombre);
  });
  }
  const initMap = () => {
    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.391911, lng: -58.661421 },
      zoom: 12,
    });
    setMap(mapInstance);
  };

  const fetchMapDetails = async () => {
    try {
        const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getMap/${id}`);
        const mapData = response.data;

        // Reorganizar las líneas para agruparlas por color
        const lineGroups = {};
        mapData.lineas.forEach((line) => {
            if (!lineGroups[line.color]) {
                lineGroups[line.color] = [];
            }
            lineGroups[line.color].push({ lat: parseFloat(line.lat), lng: parseFloat(line.lng) });
        });

        // Dibujar las líneas por color
        Object.keys(lineGroups).forEach(color => {
            const linePath = lineGroups[color];
            const polyline =  new window.google.maps.Polyline({
                path: linePath,
                geodesic: true,
                strokeColor: color,
                strokeOpacity: 1.0,
                strokeWeight: 2,
            });
            polyline.setMap(map);
            setSelectedElements(prevElements => [...prevElements, { nombre: color, type: 'linea', data: linePath, polyline }]);
        
        });

        // Reorganizar los polígonos para agruparlos por color
        const polygonGroups = {};
        
        mapData.poligonos.forEach((polygon) => {
            if (!polygonGroups[polygon.color]) {
                polygonGroups[polygon.color] = [];
            }
            polygonGroups[polygon.color].push({ lat: parseFloat(polygon.lat), lng: parseFloat(polygon.lng) });
        });
        const newPoligons = [];
        // Dibujar los polígonos por color
        Object.keys(polygonGroups).forEach(color => {
            const polygonPath = polygonGroups[color];
            const polygon =  new window.google.maps.Polygon({
                paths: polygonPath,
                strokeColor: color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: color,
                fillOpacity: 0.35,
                editable: true, // Hacer el polígono editable
                draggable: true, // Hacer el polígono arrastrable
            }) ;
            newPoligons.push(polygon);
           
            polygon.setMap(map);
            debugger
            setSelectedElements(prevElements => [
              ...prevElements,
              {
                nombre: mapData.map.description.poligonos[0],
                type: 'polygon',
                data: polygonPath,
                polygon: polygon,
              },
            ]);
             // setSelectedElements(prevElements => [...prevElements, { nombre: color, type: 'poligono', data: polygonPath, polygon }]);
           // setSelectedElements(prevElements => [...prevElements, { nombre: color, type: 'poligono', data: polygonPath, polygon }]);
          /*   for (let i = 0; i < mapData.map.description.puntosInteres.length; 
              i++) {
             
              setSelectedElements(prevElements => [
                ...prevElements,
                {
                  nombre: mapData.map.description.poligonos[i],
                  type: 'polygon',
                  data: [mapData.poligonos[i]],
                  polygon: newPoligons,
                },
              ]); 
            }  */  
        });

        // Dibujar puntos de interés
        const newMarkers = [];
        mapData.puntosInteres.forEach(point => {
          const marker =  new window.google.maps.Marker({
                position: { lat: point.lat, lng: point.lng },
                map: map,
                title: point.nombre || 'Punto de Interés',
                icon: point.icono || null, 
                draggable: true, // Hacer el marcador arrastrable
            });
            newMarkers.push(marker);
            setMarkers(prevMarkers => [...prevMarkers, marker]);
           
        });
        setMarkers(newMarkers);
        for (let i = 0; i < mapData.map.description.puntosInteres.length; i++) {
        // setSelectedElements(prevElements => [...prevElements, { nombre: mapData.map.description.puntosInteres[i], type: 'punto', data: [mapData.puntosInteres], mapData }]);
       
        setSelectedElements(prevElements => [
          ...prevElements,
          {
            nombre: mapData.map.description.puntosInteres[i],
            type: 'punto',
            data: [mapData.puntosInteres[i]],
            marker: newMarkers,
          },
        ]); 
      }   
    } catch (error) {
        console.error('Error fetching map details:', error);
        setAlertMessage('Error al cargar los detalles del mapa');
        setAlertSeverity('error');
        setAlertOpen(true);
    }
};
const openSaveMapPopup = () => {
  setSaveMapOpen(true);
};
const closeSaveMapPopup = () => {
  setSaveMapOpen(false);
};


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          if (!sheet) {
            throw new Error('No se pudo leer la hoja del archivo');
          }

          const json = XLSX.utils.sheet_to_json(sheet);

          if (json.length === 0) {
            throw new Error('La hoja está vacía');
          }

          setData(json);
          setExcelCargado(true);
          drawPolygon(json); // Dibuja el polígono en el mapa
        } catch (error) {
          console.error('Error al procesar el archivo:', error.message);
          setData([]);
          setExcelCargado(false);
          setAlertMessage('Error al cargar el archivo');
          setAlertSeverity('error');
          setAlertOpen(true);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const handleSelectElement = (nombre, data, type) => { 
    debugger;
    setSelectedElements(prevElements => {
      const existingElement = prevElements.find(el => el.nombre === nombre && el.type === type);
      
      if (existingElement) {
        // Si el elemento ya existe, lo eliminamos (deseleccionamos)
        handleRemoveElement(nombre);  // Llama a la función para eliminar el polyline
        return prevElements.filter(el => el.nombre !== nombre); // Remueve de la lista
      } else {
        // Añadir el nuevo elemento sin interactuar con el mapa aún
        const newElement = { nombre, data, type };
        return [...prevElements, newElement];
      }
    });
};
const handleSaveMap = () => {
  const mapData = selectedElements.map(el => ({
    nombre: el.nombre,
    type: el.type,
    data: el.data,
  }));

  axios.post('https://mapane3.tipicaweb.com/api/api/saveMap', { name: mapName, data: mapData })
    .then(response => {
      setAlertMessage('Mapa guardado exitosamente');
      setAlertSeverity('success');
      setAlertOpen(true);
      closeSaveMapPopup();
      setMapName(''); // Reiniciar el nombre del mapa

      // Limpiar los elementos seleccionados y los filtros
      setSelectedElements([]);
      setFilters({
        punto: '',
        linea: '',
        poligono: '',
      });
    })
    .catch(error => {
      setAlertMessage('Error al guardar el mapa');
      setAlertSeverity('error');
      setAlertOpen(true);
    });
}
  const drawPolygon = (data) => {
    if (map && data.length > 0) {
      const coordinates = data.map(row => ({
        lat: parseFloat(row.lat),
        lng: parseFloat(row.lng),
      }));

      // Si ya existe un polígono en el mapa, lo elimina antes de dibujar uno nuevo
      if (polygon) {
        polygon.setMap(null);
      }

      // Crear el polígono con las coordenadas del archivo Excel
      const newPolygon = new window.google.maps.Polygon({
        paths: coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        draggable: false, // No permite arrastrar el polígono
        editable: false,  // No permite editar el polígono
      });

      newPolygon.setMap(map);
      setPolygon(newPolygon);
    }
  };
  const fetchData = async () => {
    if (filters.punto) {
      const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getPuntosInteres/${filters.punto}`);
      const data = response.data;
      const groupedData = data.reduce((acc, punto) => {
        if (!acc[punto.nombre]) {
          acc[punto.nombre] = [];
        }
        acc[punto.nombre].push(punto);
        return acc;
      }, {});
      setPuntosInteres(groupedData);
    } else {
      setPuntosInteres([]);
    }

    if (filters.poligono) {
      const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getPolygonPoints/${filters.poligono}`);
      const data = response.data;
      const groupedData = data.reduce((acc, poligono) => {
        if (!acc[poligono.nombre_zona]) {
          acc[poligono.nombre_zona] = [];
        }
        acc[poligono.nombre_zona].push(poligono);
        return acc;
      }, {});
      setPoligonos(groupedData);
    } else {
      setPoligonos([]);
    }
  };
  const handleSave = () => {
    if (!zona || data.length === 0) {
      setAlertMessage('Zona o datos faltantes');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    axios.post('https://mapane3.tipicaweb.com/api/api/savePolygon', {
      nombre_zona: zona,
      puntos: data,
    })
      .then((response) => {
        setAlertMessage('Datos guardados exitosamente');
        setAlertSeverity('success');
        setAlertOpen(true);

        // Limpiar el contenedor del archivo y el campo de entrada
        setData([]);
        setExcelCargado(false);
        setZona('');
        document.getElementById('file-upload').value = ''; // Limpiar el input de archivo
        if (polygon) {
          polygon.setMap(null); // Limpiar el polígono del mapa
          setPolygon(null);
        }
      })
      .catch((error) => {
        setAlertMessage('Error al guardar los datos');
        setAlertSeverity('error');
        setAlertOpen(true);
      });
  };

  return (
    <div >
       <Box className="container">
    <Box className="filters-container" display="flex" justifyContent="space-between" mb={2}>
        
        {/* Autocomplete para Puntos de Interés */}
        <Box className="filter">
          <Autocomplete
            className="custom-autocomplete"
            options={Object.keys(puntosInteres).filter(nombre => 
              !selectedElements.some(el => el.nombre === nombre && el.type === 'punto') // Filtrar elementos ya seleccionados
            )}
            getOptionLabel={(option) => option}
            onInputChange={(event, newValue) => setFilters({ ...filters, punto: newValue })}
            onChange={(event, newValue) => {
              if (newValue) {
                handleSelectElement(newValue, puntosInteres[newValue], 'punto');
              }
            }}
            renderInput={(params) => (
        <TextField
                       {...params}
                       label="Capa Marcador"
                       variant="outlined"
                       fullWidth
                     />
            )}
          />
        </Box>
  
        {/* Checkbox para Recorridos de Buses */}
      <Box className="filter">
        <BusCheckboxes selectedElements={selectedElements} handleSelectElement={handleSelectElement}
        handleRemoveElement={handleRemoveElement}
        />
      </Box>
  
        {/* Autocomplete para Polígonos */}
                <Box className="filter">
                  <Autocomplete
                    className="custom-autocomplete"
                    options={Object.keys(poligonos).filter(nombre => 
                      !selectedElements.some(el => el.nombre === nombre && el.type === 'polygon') // Filtrar elementos ya seleccionados
                    )}
                    getOptionLabel={(option) => option}
                    onInputChange={(event, newValue) => setFilters({ ...filters, poligono: newValue })}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleSelectElement(newValue, poligonos[newValue], 'polygon');
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Capa Forma"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Box>
              </Box>
  
      <Grid container spacing={2} mt={2}>
        <Grid item xs={4}>
          <Box>
            <h3>Capa Marcador</h3>
            <List>
              {selectedElements
                .filter(el => el.type === 'punto')
                .map(element => (
                  <ListItem key={element.nombre}>
                    <ListItemText primary={element.nombre} />
                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveElement(element.nombre)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        
        
        <Grid item xs={4}>
          <Box>
            <h3>Capa Forma</h3>
            <List>
              {selectedElements
                .filter(el => el.type === 'polygon')
                .map(element => (
                  <ListItem key={element.nombre}>
                    <ListItemText primary={element.nombre} />
                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveElement(element.nombre)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>

      
      <Button
        id="save-map-button"
        variant="contained"
        startIcon={<SaveIcon />}
        onClick={openSaveMapPopup}
      >
        Guardar Mapa
      </Button>

      <Dialog open={saveMapOpen} onClose={closeSaveMapPopup}>
        <DialogTitle>Guardar Mapa</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre del Mapa"
            fullWidth
            value={mapName}
            
            onChange={(e) => setMapName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSaveMapPopup}>Cancelar</Button>
          <Button onClick={handleSaveMap}>Guardar</Button>
        </DialogActions>
      </Dialog>
      <div id="map" className="map"></div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      </Box>
    </div>
  );
};

export default EditMapPage;
