import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import './EditMapPage.css';

const BusCheckboxes = ({ selectedElements, handleSelectElement, handleRemoveElement }) => {
  const [busRoutes, setBusRoutes] = useState([]);
  const [selectedRoutes, setSelectedRoutes] = useState([]);

  // Cargar todas las rutas de autobuses desde el backend cuando el componente se monte
  useEffect(() => {
    const fetchAllBusRoutes = async () => {
      try {
        const response = await axios.get('https://mapane3.tipicaweb.com/api/api/getLineas');
  
        // Agrupa las rutas por su número principal
        const groupedRoutes = response.data.reduce((acc, route) => {
          // Elimina letras al final y guiones bajos seguidos de números
          const mainRoute = route.linea.replace(/(_\d+|[A-Z].*)$/, ''); // Elimina letras y guiones bajos seguidos de números
          const normalizedRoute = mainRoute.replace(/[^0-9]/g, ''); // Asegúrate de que solo queden números
  
          // Agrupa las rutas por su número principal
          if (!acc.some(r => r.linea === normalizedRoute)) {
            acc.push({ linea: normalizedRoute, color: route.color });
          }
          return acc;
        }, []);
  
        setBusRoutes(groupedRoutes);  // Actualiza busRoutes con rutas principales
      } catch (error) {
        console.error("Error fetching bus routes:", error);
      }
    };
    fetchAllBusRoutes();
  }, []);
  

  const fetchBusVariants = async (routeNumber) => {
    try {
      const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getLinea/${routeNumber}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching variants for route ${routeNumber}:`, error);
      return [];
    }
  };

  const handleRouteChange = async (routeNumber) => {
    if (selectedRoutes.includes(routeNumber)) {
      setSelectedRoutes(selectedRoutes.filter(route => route !== routeNumber));
      handleRemoveElement(routeNumber);  // Elimina el polyline
    } else {
      const variants = await fetchBusVariants(routeNumber);
      handleSelectElement(routeNumber, variants, 'bus');  // Agrega la ruta y variantes
      setSelectedRoutes([...selectedRoutes, routeNumber]);
    }
  };

  return (
    <div className="accordion-container">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordion-button">
          <h3>Recorrido de Buses</h3>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="checkbox-container">
            {busRoutes.map(route => (
              <FormControlLabel
                key={route.linea}
                control={
                  <div
                    className={`custom-checkbox ${selectedRoutes.includes(route.linea) ? 'selected' : ''}`}
                    onClick={() => handleRouteChange(route.linea)}
                  >
                    {route.linea}
                  </div>
                }
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BusCheckboxes;
